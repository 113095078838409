import React from 'react';
import { useParams } from 'react-router-dom';
import EventDetail from '../../containers/EventDetail';

export default function DetailPage() {
    const { id } = useParams();

    return (
        <EventDetail event_id={id} />
    )
}