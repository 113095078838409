import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import HomePage from './pages/HomePage';
import EventPage from './pages/events/IndexPage';
import EventDetailPage from './pages/events/DetailPage';
import RetroPage from './pages/retro/IndexPage';
import RetroDetailPage from './pages/retro/DetailPage';

export default function Routes() {
    return (
        <Router>
            <App>
                <Switch>
                    <Route exact path="/events" component={EventPage} />
                    <Route exact path="/event/:id" component={EventDetailPage} />
                    <Route exact path="/retro" component={RetroPage} />
                    <Route exact path="/retro/:id" component={RetroDetailPage} />
                    <Route exact path="/" component={HomePage} />
                </Switch>
            </App>
        </Router>
    );
}