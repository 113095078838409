import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

class Header extends React.Component {
    render(){
        return (
            <Container fluid="md">
                <Navbar expand="md" variant="light" bg="transparent">
                    <Navbar.Brand href="/" style={{color: 'white', fontSize: '2em'}}>CMU Shanghai Tartans</Navbar.Brand>
                </Navbar>
            </Container>
        );
    }
}

export default Header;
