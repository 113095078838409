/**
 * return a string
 * @param {Date Object} date use new Date function
 * @return String
 */
export const getChineseDate = (date) => {
    const week_array = ["周日","周一","周二","周三","周四","周五","周六"];

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var week = week_array[date.getDay()];

    return month + "月" + day + "日" + " "+ week;
}
  
/**
 * return a string
 * @param {Date Object} date use new Date function
 * @return string
 */
export const getChineseTime = (date) => {
    var hour = date.getHours();
    var min = date.getMinutes();
    min = ("00" + min).substr(-2);

    return hour + ":" + min;
}  

/**
 * get chinese event time interval
 * @param {String} Starttime as 2019-05-06 13:00:00
 * @param {String} Endtime as 2019-05-06 16:00:00
 */
export const getChineseEventTime = (starttime, endtime) => {
    var _start = starttime.replace(/-/g, "/");
    var _end = endtime.replace(/-/g, "/");

    var start = new Date(_start);
    var end = new Date(_end);

    var start_date = getChineseDate(start);
    var start_time = getChineseTime(start);

    var end_date = getChineseDate(end);
    var end_time = getChineseTime(end);

    if(start_date === end_date){
        return start_date + " " + start_time + "-" + end_time;
    }
    else{
        return start_date + " " + start_time + " - " + end_date + " " + end_time;
    }
}