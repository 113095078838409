import React from 'react';
import { Spinner } from 'react-bootstrap';
import '../assets/css/wp.css';
import { getRetrospectDetail } from '../api/event';

class RetroDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            post_id: props.post_id,
            event: 'loading',
        };
    }

    componentDidMount(){        
        getRetrospectDetail(this.state.post_id).then((data) => {
            this.setState({event: data});
            document.title = data.post_title;
        });
    }

    render(){
        if(this.state.event === 'loading'){
            return (
                <>
                    <Spinner animation="border" variant="danger"/>
                    <p>正在加载详情，请耐心等候...</p>
                </>
            );
        }
        else {
            return(
                <article id="wp-article-content" data-public-time={this.state.event.post_date}>
                    <h1>{this.state.event.post_title}</h1>
                    <pre dangerouslySetInnerHTML={{ __html: this.state.event.post_content }} />
                    
                </article>
            )
        }
    }
}

export default RetroDetail;