import axios from 'axios';

export const getHybrid = async () => {
    const url = '/api/cmus?tenant=5cbd61bfed2fe';
    try{
        const response = await axios.get(url);
        const { success, data } = response.data;
        if(success){
            return data;
        }
        else {
            throw data;
        }
    } catch(error) {
        console.log(error);
    }
}

export const getEvents = async () => {
    const url = '/api/cmus/events?tenant=5cbd61bfed2fe';
    try{
        const response = await axios.get(url);
        const { success, data } = response.data;
        if(success){
            return data;
        }
        else {
            throw data;
        }
    } catch(error) {
        console.log(error);
    }
}

export const getEventDetail = async (id) => {
    const url = `/api/events/${id}?tenant=5cbd61bfed2fe`;
    try {
        const response = await axios.get(url);
        const { success, data } = response.data;
        if(success){
            return data;
        }
        else {
            throw data;
        }
    } catch(error) {
        console.log(error);
    }
};

export const getRetrospects = async () => {
    const url = '/api/cmus/retrospect?tenant=5cbd61bfed2fe';
    try {
        const response = await axios.get(url);
        const { success, data } = response.data;
        if(success){
            return data;
        }
        else {
            throw data;
        }
    } catch(error) {
        console.log(error);
    }
};

export const getRetrospectDetail = async (id) => {
    const url = `/api/posts/${id}?tenant=5cbd61bfed2fe`;
    try {
        const response = await axios.get(url);
        const { success, data } = response.data;
        if(success){
            return data;
        }
        else {
            throw data;
        }
    } catch(error) {
        console.log(error);
    }
}
