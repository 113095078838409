import React from 'react';
import { Link } from 'react-router-dom';
import {CardDeck, Card } from 'react-bootstrap';
import { getRetrospects } from '../api/event';

class Retro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {events: []};
    }

    componentDidMount(){
        getRetrospects().then((data) => {
            this.setState({
                events: data
            });
        })
    }

    render(){
        return (
            <CardDeck style={{ display: 'block' }}>
                { this.state.events.map((event) => { return (
                    <Card style={{ width: '100%', margin: "1em 0" }} key={event.id}>
                        <Card.Img variant="top" src={event.thumbnail} title={event.post_title} />
                        <Card.Body>
                            <Card.Title>{event.post_title}</Card.Title>
                            <Card.Text className="text-ellipsis">{event.post_excerpt}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Link to="#">活动回顾</Link>
                            <Link to={`detail/${event.id}?type=${event.post_type}`}>更多</Link>
                        </Card.Footer>
                    </Card>);
                })}
            </CardDeck>
        )
    }
}

export default Retro;
