import React from 'react';
import Container from 'react-bootstrap/Container'

class Footer extends React.Component {
    year = (new Date()).getFullYear();

    render(){
        return (
            <Container fluid="md">
                <div className="copyright-icp">
                    <p className="copyright"><span>©{this.year} Copyright by shanghaitartans.org.cn</span></p>
                    <p className="icp">
                        <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">浙ICP备20007247号</a>
                    </p>
                </div>
            </Container>
        );
    }
}

export default Footer;
