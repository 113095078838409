import React from 'react';
import { Jumbotron, Spinner, Badge } from 'react-bootstrap';
import { getEventDetail } from '../api/event';
import { getChineseEventTime } from '../utils/utility';

class EventDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            event_id: props.event_id,
            event: 'loading',
        };
    }

    componentDidMount(){        
        getEventDetail(this.state.event_id).then((data) => {
            this.setState({event: data});
            document.title = data.post_title;
        });
    }

    render(){
        if(this.state.event === 'loading'){
            return (
                <>
                    <Spinner animation="border" variant="danger"/>
                    <p>正在加载详情，请耐心等候...</p>
                </>
            );
        }
        else {
            const post_mob_pics = this.state.event.post_mobile_pic.split(',');
            const event_time = getChineseEventTime(this.state.event.begin_time, this.state.event.end_time)

            return(
                <>
                    <Jumbotron style={{ marginTop: '1em' }}>
                        <img src={this.state.event.thumbnail} alt={this.state.event.post_title} width="100%" style={{ marginTop: '-1em' }} />
                        <h1 style={{ fontSize: '1.58em', margin: "0.56em 0" }}>
                            {this.state.event.post_title}
                            <Badge variant="success">{this.state.event.is_expired ? '已结束': '进行中'}</Badge>
                        </h1>
                        <p style={{ fontSize: '1.2em', color: 'green', fontWeight: '500', marginBottom: '0.56em' }}>¥ {this.state.event.price}</p>
                        <p style={{ color: 'gray', marginBottom: '0.56em' }}><span>时间：</span>{event_time}</p>
                        <p style={{ color: 'gray', marginBottom: '0.56em' }}><span>地点：</span>{this.state.event.address}</p>
                        <p style={{ color: 'gray', marginBottom: '0.56em' }}><span>联系：</span>{this.state.event.contact}</p>
                    </Jumbotron>
                    <Jumbotron style={{ textAlign: 'center' }}>
                    {
                        post_mob_pics.map((pic, index)=>{
                            return <img src={pic} alt={index} style={{maxWidth: "100%"}} />
                        })
                    }
                    </Jumbotron>
                </>
            )
        }
    }
}

export default EventDetail;