import Header from './Header';
import Footer from './Footer';
import Container from 'react-bootstrap/Container'

export default function App(props) {
  const { children } = props;
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <main className="App-content" id="content">
        <Container style={{ maxWidth: "720px", paddingLeft: '0px', paddingRight: '0px' }}>
          {children}
        </Container>
      </main>
      <footer className="App-footer">
        <Footer />
      </footer>
    </div>
  );
}
