import React from 'react';
import { Link } from 'react-router-dom';
import {CardDeck, Card } from 'react-bootstrap';
import { getHybrid } from '../api/event';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {events: []};
    }

    componentDidMount(){
        getHybrid().then((data) => {
            this.setState({
                events: data
            });
        })
    }

    handleCardClick = (e, post_id, post_type) => {
        e.preventDefault();
        e.stopPropagation();

        if(post_type === 'event'){
            alert('event');
        }
        else if( post_type === 'post'){
            alert('post');
        }
        else{
            alert('Wrong Card Type');
        }
        
    }

    render(){
        return (
            <CardDeck style={{ display: 'block' }}>
                { this.state.events.map((event) => { return (
                    <Card style={{ width: '100%', margin: "1em 0" }} key={event.post_id}>
                        <Card.Img variant="top" src={event.thumbnail} title={event.post_title} />
                        <Card.Body>
                            <Card.Title>{event.post_title}</Card.Title>
                            <Card.Text className="text-ellipsis">{event.post_excerpt}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            {
                                event.post_type === "event"
                                ? <Link to={`event/${event.post_id}`}>点击查看更多</Link>
                                : <Link to={`retro/${event.post_id}`}>点击查看更多</Link>
                            }
                        </Card.Footer>
                    </Card>);
                })}
            </CardDeck>
        )
    }
}

export default Home;
